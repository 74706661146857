<template>
  <div>
    <template v-if="type == controlStatus.readonly">
      <div v-if="selectedItem">
        <span
          >{{
            $t($enumLangkey("signatureType", selectedItem.signatureType))
          }}
          ({{ $t("logistics.service_charge") }} {{ $filters.formatCurrencyAmount(symbol, selectedItem.fee, false) }})</span
        >
      </div>
    </template>
    <template v-else-if="type == controlStatus.preview">
      <div>
        <span>{{ $t("common.signature") }}:</span>
        <div class="pl-2" v-for="(item, index) in signFeeOptions" :key="index">
          <slot name="icon"></slot>
          {{ $t($enumLangkey("signatureType", item.signatureType)) }} ({{
            $t("logistics.service_charge")
          }} {{ $filters.formatCurrencyAmount(symbol, item.fee, false) }})
        </div>
      </div>
    </template>
    <template v-else-if="type == controlStatus.editable">
      <div>
        <span>{{ $t("common.signature") }}:</span>
        <div class="pl-2">
          <a-radio
            :checked="selectedKey == item.signatureType"
            :value="item.signatureType"
            v-for="(item, index) in signFeeOptions"
            :key="index"
            @click.prevent="handleSignatureTypeClick(item)"
          >
            {{ $t($enumLangkey("signatureType", item.signatureType)) }} ({{
              $t("logistics.service_charge")
            }} {{ $filters.formatCurrencyAmount(symbol, item.fee, false) }})
          </a-radio>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, computed, ref } from "vue";
import { Checkbox, Radio } from "ant-design-vue";

const controlStatus = {
  readonly: "readonly",
  preview: "preview",
  editable: "editable",
};
export default defineComponent({
  components: {
    ACheckbox: Checkbox,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
  },
  props: {
    // signatureType枚举，可空
    value: {
      type: Number,
    },
    // 金额符号
    symbol: String,
    // 费用列表
    signFeeOptions: {
      type: Array,
      default: () => [],
    },
    // 在显示详细时，只显示选中的
    // readonly, preview, editable
    type: {
      type: String,
      default: controlStatus.readonly,
    },
  },
  setup(props, { emit }) {
    const selectedKey = ref(props.value);

    watch(
      () => props.value,
      (newVal) => {
        selectedKey.value = newVal;
      }
    );
    watch(selectedKey, (newVal) => {
      emit("update:value", newVal);
    });

    const state = reactive({
      // 是否选中签名签收checkbox
      isSelected: false,
    });

    watch(
      () => props.value,
      (val) => {
        state.isSelected = !!val;
      },
      { immediate: true }
    );

    const selectedItem = computed(() => {
      return props.signFeeOptions?.find(
        (x) => x.signatureType == selectedKey.value
      );
    });

    const handleSignatureTypeClick = (item) => {
      if (selectedKey.value == item.signatureType) {
        selectedKey.value = null;
      } else {
        selectedKey.value = item.signatureType;
      }
    };

    return {
      controlStatus,
      selectedKey,
      selectedItem,

      handleSignatureTypeClick,
    };
  },
});
</script>

<style scoped></style>
