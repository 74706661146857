<template>
  <div>
    <a-modal
      :visible="visible"
      :title="$t('waybill.check_the_postcode')"
      @cancel="handleClose"
    >
      <template #footer>
        <a-button key="submit" @click="handleClose">{{$t("common.close")}}</a-button>
      </template>
      <div class="mb-2">
        <a-input-search
          v-model:value="data.postCode"
          :placeholder="$t('common.postcode')"
          enter-button
          @search="handleQuery"
          :loading="data.loading"
        />
      </div>

      <div class="mb-2">
        <a-table
          size="small"
          :columns="columns"
          :data-source="data.items"
          :pagination="data.pagination"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :loading="data.loading"
          @change="handleTableChange"
        >
            <template #addressRender="{record}">
                {{getAddress(record)}}
            </template>
            <template #operateRender="{record}">
                <a-button @click="handleSelected(record)">
                    {{$t("common.select")}}
                </a-button>
            </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, computed, watch, onMounted, defineExpose } from "vue";
import {
  getByCountryIdAndZipCode,
  getSupportCountryId,
} from "../../api/modules/postQuery/index";
import { Modal, Button, Input, Table } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getAddressByLanguageV2 } from "../../utils/general"
export default {
  name: "CPostQuery",
  components: {
    AButton: Button,
    AInput: Input,
    AModal: Modal,
    AInputSearch: Input.Search,
    ATable: Table,
  },
  props: {
    postCode: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    emits: ["onSelected"],
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const data = reactive({
      postCode: props.postCode,
      loading: false,
      items: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    });
    const { getters } = useStore();

    watch(
      () => props.postCode,
      (newVal) => {
        data.postCode = newVal;
      }
    );
    watch(
      () => props.visible,
      (newVal) => {
        if (newVal) {
          handleQuery();
        }
        // emit("update:visibled", newVal);
      }
    );

    const columns = [
      {
        width: 300,
        title: () => vueI18n.t("waybill.region"),
        slots: {
          customRender: "addressRender",
        },
      },
      {
        width: 80,
        title: () => "",
        slots: {
          customRender: "operateRender",
        },
      },
    ];

    const handleTableChange = (pageData) => {
      data.pagination = pageData
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(Object.assign({
          countryIos2:address.ioS2,
          provinceAbbr: address.abbrCode
      },address), getters.language, ", ");
    }

    const handleQuery = () => {
      if (!data.postCode) {
        return;
      }
      data.loading = true;
      var postCode = data.postCode;
      getByCountryIdAndZipCode({ zipCode: data.postCode })
        .then((res) => {
          var dataArr = res.result?.areaInfos || [];
          data.items = dataArr.map((x) => {
            return Object.assign({}, x, { postCode });
          });
          data.pagination.total = data.items.length
        })
        .catch(() => {})
        .finally(() => {
          data.loading = false;
        });
    };

    const handleSelected = (record) => {
      emit("onSelected", Object.assign({}, record));
      emit("update:visible", false);
    };

    const handleClose = () => {
      emit("update:visible", false);
    };

    onMounted(async () => {
      handleQuery();
    });

    return {
      columns,
      getAddress,

      handleQuery,
      handleSelected,
      handleClose,
      handleTableChange,

      data,
    };
  },
};
</script>
