import client from "../../client";

const Api = {
    //查询邮编对应的国家省市区
    getByCountryIdAndZipCode: "/api/BasicDataService/AreaZipcodeRelation/GetByCountryIdAndZipCode",

    //获取支持邮编查询的国家
    getSupportCountryId: "/api/BasicDataService/AreaZipcodeRelation/GetSupportCountryId",
};

//获取支持邮编查询的国家
export const getByCountryIdAndZipCode = ({ countryId, zipCode }) => {
    return client.request({
        url: Api.getByCountryIdAndZipCode,
        data: { countryId, zipCode },
        method: "POST",
    });
}

//获取支持邮编查询的国家
export const getSupportCountryId = () => {
    return client.request({
        url: Api.getSupportCountryId,
        method: "POST",
    });
}