import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  joinDownloadTemplateUrl: "/api/WarehouseService/WaybillPlan/GenerateTemplate",
  parseFile: "/api/WarehouseService/WaybillPlan/ParseExcelData",
  validationTableData: "/api/WarehouseService/WaybillPlan/ValidImport",
  getSupplierByBatchWayBill:
    "/api/WarehouseService/SupplierLogistics/GetSupplierByBatchWayBill",
  getSupplierByBatchWayBillDetail:
    "/api/WarehouseService/SupplierLogistics/GetSupplierFeeDetailByBatchWayBill",
  createBatchWayBill: "/api/WarehouseService/WaybillPlan/CreateBatchWayBill",
};

export const joinDownloadTemplateUrl = () => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.joinDownloadTemplateUrl
  );
  return url;
};

export const parseFile = (data) => {
  return client.request({
    url: Api.parseFile,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// customerNo,
// fromISO2,
// tranPlanCharacteristics,
// channelCode,
// vat,
// isChooseDDP,
// signatureType,
// length,
// width,
// height,
// weight,
// count,
// destinationType,
// toSEWarehouseNo,
// toFBAWarehouseNo,
// toOtherReceipt,
// toOtherAreaCode,
// toOtherPhoneNumber,
// toOtherISO2,
// toOtherAbbrCode,
// toOtherCityEnName,
// toOtherAddress,
// toOtherAddress2,
// toOtherPostCode,
// toMCDWarehouseNo,
export const validationTableData = (data) => {
  return client.request({
    url: Api.validationTableData,
    data,
    method: "post",
  });
};

export const getSupplierByBatchWayBill = (data) => {
  return client.request({
    url: Api.getSupplierByBatchWayBill,
    data,
    method: "post",
  });
};

export const getSupplierByBatchWayBillDetail = (data) => {
  return client.request({
    url: Api.getSupplierByBatchWayBillDetail,
    data,
    method: "post",
  });
};

export const createBatchWayBill = (data) => {
  return client.request({
    url: Api.createBatchWayBill,
    data,
    method: "post",
  });
};
