import client from "../../client";

const Api = {
  create: "/api/WarehouseService/WaybillPlan/Create",
  update:"/api/WarehouseService/WaybillPlan/Update",
  //始发国家
  getSourceCountrys: "/api/WarehouseService/WaybillPlan/GetSupplierCountry",
  //se和fba 仓库信息
  getSeTargetWarehouseInfos: "/api/WarehouseService/Warehouse/GetList",
  getFbaTargetWarehouseInfos: "/api/BasicDataService/PlatformAddress/GetList",

  getDetails: "/api/WarehouseService/WaybillPlan/Get",
};

export const create = ({
  customerNo,
  destinationType,
  tranPlanCharacteristics,
  toWarehoueOrFbaOrMcdId,
  from,
  toPersonal,
  details,
}) => {
  return client.request({
    url: Api.create,
    data: {
      customerNo,
      destinationType,
      tranPlanCharacteristics,
      toWarehoueOrFbaOrMcdId,
      from,
      toPersonal,
      details,
    },
    method: "post",
  });
};

export const update = ({
  id,
  customerNo,
  destinationType,
  tranPlanCharacteristics,
  toWarehoueOrFbaOrMcdId,
  from,
  toPersonal,
  details,
}) => {
  return client.request({
    url: Api.update,
    data: {
      id,
      customerNo,
      destinationType,
      tranPlanCharacteristics,
      toWarehoueOrFbaOrMcdId,
      from,
      toPersonal,
      details,
    },
    method: "post",
  });
};

export const getSourceCountrys = () => {
  return client.request({
    url: Api.getSourceCountrys,
    //data: { },
    method: "get",
  });
};

export const getSeTargetWarehouseInfos = () => {
  return client.request({
    url: Api.getSeTargetWarehouseInfos,
    //data: { },
    method: "post",
  });
};

export const getFbaTargetWarehouseInfos = (platform = 1) => {
  return client.request({
    url: Api.getFbaTargetWarehouseInfos,
    data: { platform },
    method: "post",
  });
};

export const getDetails = (id) => {
  return client.request({
    url: Api.getDetails,
    params: { id },
    method: "get",
  });
};
