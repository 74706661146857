<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.batch_import_of_waybills") }}
    </template>
    <template #contentBody="wrap">
      <div class="p-2">
        <p>
          1. {{ $t("warehouse.please_first")
          }}<a-button
            type="link"
            size="small"
            :loading="data.downloadLoading"
            @click="hanldeDownloadTemplateFile"
            >{{ $t("warehouse.download_template") }}</a-button
          >
          ,{{ $t("warehouse.then_follow_the_prompts_to_complete") }}
        </p>
        <p>2. {{ $t("warehouse.consignment_order_bulk_import_text2") }}</p>
        <!-- <p>3. {{ $t("warehouse.consignment_order_bulk_import_text3") }}</p>
        <p>4. {{ $t("warehouse.consignment_order_bulk_import_text4") }}</p> -->
      </div>
      <div class="p-2" v-show="!data.listVisible">
        <a-row>
          <a-col>
            <a-upload
              :customRequest="handleParseFile"
              accept=".xlsx"
              :showUploadList="false"
            >
              <a-button :loading="data.loading">
                <upload-outlined></upload-outlined>
                {{ $t("warehouse.select_import_file") }}
              </a-button>
            </a-upload>
          </a-col>
        </a-row>
      </div>

      <a-card v-show="data.listVisible">
        <a-table
          class="mt-3"
          :columns="columns"
          size="small"
          :data-source="data.list"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :loading="data.loading"
        >
          <template #serial_number="{ record, index }">
            {{ index + 1 }}
            <a-popover placement="right" v-if="!record.isNormal">
              <template #content>
                <div style="max-width: 400px">
                  <a-descriptions :title="$t('warehouse.data_error')">
                  </a-descriptions>
                  <a-descriptions
                    :column="1"
                    v-if="record?.errObjs && record?.errObjs.length > 0"
                  >
                    <a-descriptions-item
                      v-for="(errObj, index) in record?.errObjs"
                      :key="index"
                    >
                      {{ index + 1 }}.
                      {{ $t(getErrorMsgKey(errObj.errCode)) }}
                      <span v-if="errObj?.fields && errObj?.fields.length > 0"
                        >(</span
                      >
                      <template
                        v-if="errObj?.fields && errObj?.fields.length > 0"
                      >
                        <span v-for="(field, i) in errObj?.fields" :key="i">
                          {{ field }}
                          <span v-if="i != errObj?.fields?.length - 1">,</span>
                        </span>
                      </template>
                      <span v-if="errObj?.fields && errObj?.fields.length > 0"
                        >)</span
                      >
                    </a-descriptions-item>
                  </a-descriptions>
                </div>
              </template>

              <div class="text-error">
                <span>{{ $t("warehouse.data_error") }}</span>
                <span class="ml-1 mr-1">
                  <QuestionCircleOutlined />
                </span>
              </div>
            </a-popover>
            <div class="text-error" v-else-if="record.isShowBoxError">
              <span>{{ $t("warehouse.box_data_error") }}</span>
            </div>
          </template>

          <template #orderNo="{ record }">
            <span v-if="record.customerNo">
              {{ record.customerNo }}
            </span>
            <span v-else>-</span>
            <div>
              <a @click="handleShowBaseInfoModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <template #originating_country="{ record }">
            <div v-if="record.from">
              {{ getLanguageName(record.from) }} ({{ record.from.ioS2 }})
            </div>
            <span v-else>-</span>
            <div>
              <a @click="handleShowBaseInfoModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <template #destinationType="{ record }">
            <span v-if="record.destinationType">
              {{ $t($enumLangkey("destinationType", record.destinationType)) }}
            </span>
            <span v-else>-</span>
            <div>
              <a @click="handleShowBaseInfoModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <template #warehouse_code="{ record }">
            <div v-if="record.to">
              <span
                v-if="record.destinationType == destinationTypeEnum.SeWarehouse"
              >
                {{ record.to?.seWarehouse?.warehouseNo ?? "-" }}
              </span>
              <span v-if="record.destinationType == destinationTypeEnum.FBA">
                {{ record.to?.fbaWarehouse?.name ?? "-" }}
              </span>
              <span
                v-if="
                  record.destinationType == destinationTypeEnum.OtherAddress
                "
              >
                -
              </span>
            </div>
            <div
              v-if="record.destinationType != destinationTypeEnum.OtherAddress"
            >
              <a @click="handleShowBaseInfoModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <template #recipientsAndPhone="{ record }">
            <div
              v-if="record.destinationType == destinationTypeEnum.OtherAddress"
            >
              {{ record.to?.other?.name }}
              {{ record.to?.other?.telephoneCountryTelCode }}
              {{ record.to?.other?.telephone }}
            </div>
            <div v-else>-</div>
            <div
              v-if="record.destinationType == destinationTypeEnum.OtherAddress"
            >
              <a @click="hanldeShowAddressModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <template #destination_address="{ record }">
            <div
              v-if="
                record.destinationType == destinationTypeEnum.OtherAddress &&
                record.to.other
              "
            >
              {{ getAddress(record.to.other) }}
            </div>
            <div v-else>-</div>
            <div
              v-if="record.destinationType == destinationTypeEnum.OtherAddress"
            >
              <a @click="hanldeShowAddressModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <template #transport_characteristics="{ record }">
            <!-- 运输特征 -->
            <span v-if="record.tranPlanCharacteristics?.length > 0">
              <a-row :gutter="[8, 8]">
                <a-col
                  v-for="(item, index) in record.tranPlanCharacteristics"
                  :key="index"
                >
                  {{ $t($enumLangkey("transportCharacteristics", item)) }}
                </a-col>
              </a-row>
            </span>
            <div v-else>-</div>
            <div>
              <a @click="handleShowBaseInfoModal(record)">
                <EditOutlined />
              </a>
            </div>
          </template>

          <!-- 运输方案 -->
          <template #transport_scheme="{ record }">
            <div style="min-width: 200px">
              <!-- 物流方案 模板 -->
              <div>
                <span>{{ $t("logistics.se_logistics") }}:</span>
                {{
                  record?.supplierLogisticScheme?.logisticsBaseInfo?.name ?? "-"
                }}
                <div style="color: RGB(128, 128, 128)">
                  <small
                    >[ {{ $t("logistics.delivery_way") }}:
                    <span
                      v-if="
                        record?.supplierLogisticScheme?.logisticsBaseInfo
                          ?.deliveryType
                      "
                      >{{
                        $t(
                          $enumLangkey(
                            "deliveryWay",
                            record?.supplierLogisticScheme?.logisticsBaseInfo
                              ?.deliveryType
                          )
                        )
                      }};</span
                    >
                    <span v-else>-</span>
                    {{ $t("logistics.selected_service") }}:
                    <template
                      v-if="
                        record?.supplierLogisticScheme?.logisticsRegionInfo
                          ?.isChooseDdp ||
                        record?.supplierLogisticScheme?.logisticsRegionInfo
                          ?.isChooseSign
                      "
                    >
                      <span
                        v-if="
                          record?.supplierLogisticScheme?.logisticsRegionInfo
                            ?.isChooseDdp
                        "
                      >
                        DDP<span
                          v-if="
                            record?.supplierLogisticScheme?.logisticsRegionInfo
                              ?.isChooseSign
                          "
                          >,</span
                        >
                      </span>

                      <span
                        v-if="
                          record?.supplierLogisticScheme?.logisticsRegionInfo
                            ?.signatureType
                        "
                      >
                        {{
                          $t(
                            $enumLangkey(
                              "signatureType",
                              record?.supplierLogisticScheme
                                ?.logisticsRegionInfo?.signatureType
                            )
                          )
                        }}
                      </span>
                    </template>
                    <span v-else>-</span>]
                  </small>
                </div>
                <div
                  v-if="
                    record?.supplierLogisticScheme?.logisticsRegionInfo
                      ?.isVatRequired
                  "
                >
                  <span style="color: red">*</span>
                  <label> VAT: </label>
                  <a-input
                    v-model:value="
                      record.supplierLogisticScheme.logisticsRegionInfo.vatCode
                    "
                    style="max-width: 150px"
                    size="small"
                  />
                </div>

                <a @click="hanldeShowLogisticsModal(record)"
                  ><EditOutlined
                /></a>
              </div>
            </div>
          </template>

          <template #delete="{ record, index }">
            <a-popconfirm
              :title="$t('common.are_you_sure')"
              :ok-text="$t('common.confirm')"
              :cancel-text="$t('common.cancel')"
              @confirm="hanldeDeleteData(index)"
            >
              <a-button type="primary" ghost size="small">
                {{ $t("common.delete") }}
              </a-button>
            </a-popconfirm>
          </template>

          <!-- 嵌套表格 -->
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumnsImport"
              size="small"
              :data-source="record.boxes"
              :pagination="false"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
            >
              <template #serialNumber="{ index }">
                {{ index + 1 }}
              </template>
              <template #weight="{ record }">
                <span v-if="record.weight">{{ record.weight }}kg</span>
                <span v-else>-</span>
                <div>
                  <a @click="handleShowBoxModal(record)">
                    <EditOutlined />
                  </a>
                </div>
              </template>
              <template #size="{ record }">
                <span v-if="record.length"
                  >{{ record.length }}x{{ record.width }}x{{
                    record.height
                  }}cm</span
                >
                <span v-else>-</span>
                <div>
                  <a @click="handleShowBoxModal(record)">
                    <EditOutlined />
                  </a>
                </div>
              </template>
              <template #girth="{ record }">
                <span v-if="record.girth">{{ record.girth }}cm </span>
                <span v-else>-</span>
              </template>
              <template #volume="{ record }">
                <span v-if="record.volume"
                  >{{ cmCubicToM(record.volume) }}m<sup>3</sup></span
                >
                <span v-else>-</span>
              </template>

              <template #errorMsg="{ record }">
                <a-popover placement="top" v-if="!record.isNormal">
                  <template #content>
                    <div style="max-width: 400px">
                      <a-descriptions :title="$t('warehouse.data_error')">
                      </a-descriptions>

                      <a-descriptions
                        :column="1"
                        v-if="record?.errObjs && record?.errObjs.length > 0"
                      >
                        <a-descriptions-item
                          v-for="(errObj, index) in record?.errObjs"
                          :key="index"
                        >
                          {{ index + 1 }}.
                          {{ $t(getErrorMsgKey(errObj.errCode)) }}
                          <span
                            v-if="errObj?.fields && errObj?.fields.length > 0"
                            >(</span
                          >
                          <template
                            v-if="errObj?.fields && errObj?.fields.length > 0"
                          >
                            <span v-for="(field, i) in errObj?.fields" :key="i">
                              {{ field }}
                              <span v-if="i != errObj?.fields?.length - 1"
                                >,</span
                              >
                            </span>
                          </template>
                          <span
                            v-if="errObj?.fields && errObj?.fields.length > 0"
                            >)</span
                          >
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </template>

                  <div class="text-error">
                    <span>{{ $t("warehouse.data_error") }}</span>
                    <span class="ml-1 mr-1">
                      <QuestionCircleOutlined />
                    </span>
                  </div>
                </a-popover>
                <span v-else style="color: RGB(128, 128, 128)">{{
                  $t("common.nothing")
                }}</span>
              </template>

              <template #box_amount="{ record }">
                <span v-if="record.boxCount">{{ record.boxCount }} </span>
                <span v-else>-</span>
                <div>
                  <a @click="handleShowBoxModal(record)">
                    <EditOutlined />
                  </a>
                </div>
              </template>

              <template #deleteProduct="{ record, index }">
                <a-popconfirm
                  :title="$t('common.are_you_sure')"
                  :ok-text="$t('common.confirm')"
                  :cancel-text="$t('common.cancel')"
                  @confirm="hanldeDeleteBoxData(record, index)"
                >
                  <a-button type="primary" ghost size="small">
                    {{ $t("common.delete") }}
                  </a-button>
                </a-popconfirm>
              </template>
            </a-table>
          </template>
        </a-table>

        <a-row type="flex" justify="end">
          <a-col>
            {{
              $t(
                "warehouse.note_data_can_only_be_imported_if_all_data_is_correct"
              )
            }}
          </a-col>
        </a-row>
        <a-row type="flex" justify="end" class="mt-2">
          <a-col>
            <a-button @click="data.listVisible = false" :loading="data.loading">
              {{ $t("common.cancel") }}
            </a-button>

            <a-button
              class="ml-5"
              type="primary"
              :disabled="funcWhetherCanImport()"
              :loading="data.loading"
              @click="hanldeShowImporMessage"
            >
              {{ $t("common.import") }}
            </a-button>
          </a-col>
        </a-row>
      </a-card>

      <CAddressModal
        v-model:addressData="addressModal.addressData"
        :isShowSelectCommonAddress="true"
        v-model:visible="addressModal.visible"
        @alterAddressConfirm="handleAlterAddress"
      ></CAddressModal>

      <!-- 重新选择物流方案 -->
      <a-modal
        v-model:visible="logistics.visible"
        :title="$t('logistics.logistics_scheme')"
        :footer="null"
        :maskClosable="false"
        :centered="true"
        :width="1400"
        :confirm-loading="logistics.loading"
      >
        <div>
          <a-table
            :columns="logistics.columns"
            :data-source="logistics?.list"
            :pagination="false"
            size="small"
            :scroll="{ x: true, y: true }"
            :loading="logistics.loading"
            :locale="{
              emptyText: $t(
                'logistics.not_currently_available_logistics_scheme'
              ),
            }"
          >
            <template #title>
              <a-button
                v-if="logistics.selectedLogisticsSchemeId"
                @click="hanldeShowLogisticsModal(logistics.currentLogisticInfo)"
                >{{ $t("logistics.reselect") }}
              </a-button>
            </template>

            <template #optionCustom="{ record }">
              <a-button
                v-if="!logistics.selectedLogisticsSchemeId"
                @click="handleSelectLogistics(record)"
                >{{ $t("common.select") }}</a-button
              >
            </template>
            <template #logisticsSchemeCustom="{ record }">
              <div class="text-warning">
                {{ record.scheme }} / {{ record.code }}
              </div>
              <a-row :gutter="[8, 8]">
                <a-col v-for="item in record.descriptionItems" :key="item">
                  <CheckCircleOutlined
                    class="text-warning mr-1"
                    :style="{ fontSize: '12px' }"
                  />
                  <span style="font-size: 12px; color: rgba(102, 102, 102, 1)">
                    {{ item }}
                  </span>
                </a-col>
              </a-row>
            </template>
            <template #logisticsWayCustom="{ record }">
              {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
            </template>
            <template #deliveryWayCustom="{ record }">
              {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
            </template>
            <template #referenceAgingCustom="{ record }">
              <div>
                {{ record.daysMin }}-{{ record.daysMax }}
                {{ $t("warehouse.workday") }}
                ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
              </div>
            </template>
            <template #freightsCustom="{ record }">
              <div class="text-warning">
                {{ $t("warehouse.average_unit_price") }}:
                {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.averagePrice) }}
              </div>
              <div class="text-warning">
                {{ $t("warehouse.total_prices") }}: {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.expressFee) }}
              </div>
              <div
                style="font-size: 10px; color: #808080"
                v-if="!logistics?.selectedLogisticsSchemeId"
              >
                {{ $t("warehouse.tooltip_text_6") }}
              </div>
            </template>
            <template #detailsCustom="{ record }">
              <div v-if="record.logisticsCalcMethod == 0">
                {{ $t("logistics.chargeable_weight") }}:
                {{ record.chargeableWeight }}kg
              </div>
              <div v-else>
                {{ $t("logistics.chargeable_volume") }}:
                {{ record.chargeableVolume }}m<sup>3</sup>
              </div>
              <div>
                {{ $t("logistics.volume_weight_coefficient")
                }}{{ record.volumeWeightParam }}
              </div>
            </template>
            <template #optionalServicesCustom="{ record }">
              <div v-if="logistics.selectedLogisticsSchemeId">
                <div v-if="record.logisticsRegionInfo.isDdpSupported">
                  <a-checkbox
                    v-model:checked="record.logisticsRegionInfo.isChooseDdp"
                  >
                    DDP(
                    {{ record.currencySymbol }}
                    {{
                      $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)
                    }}
                    )
                  </a-checkbox>
                </div>
                <div v-if="record.logisticsRegionInfo.isSignSupported">
                  <CSignSupported
                    v-model:value="record.logisticsRegionInfo.signatureType"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                    :type="'editable'"
                  />
                </div>
              </div>
              <div v-else>
                <div v-if="record.logisticsRegionInfo.isDdpSupported">
                  DDP(
                  {{ record.currencySymbol }}
                  {{
                    $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)
                  }}
                  )
                </div>
                <div v-if="record.logisticsRegionInfo.isSignSupported">
                  <CSignSupported
                    v-model:value="record.logisticsRegionInfo.signatureType"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                    :type="'preview'"
                  />
                </div>
              </div>
            </template>

            <template #footer="{ record }">
              <a-row
                type="flex"
                justify="end"
                v-if="logistics.selectedLogisticsSchemeId"
              >
                <a-col>
                  <a-button
                    type="primary"
                    @click="handleConfirmLogistics(record)"
                    >{{ $t("common.confirm") }}
                  </a-button>
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>
      </a-modal>

      <!-- 重新填写基本信息 -->
      <a-modal
        v-model:visible="baseInfoModal.visible"
        :title="$t('warehouse.waybill_info')"
        :maskClosable="false"
        :centered="true"
        :width="800"
        :confirm-loading="baseInfoModal.loading"
        :cancelText="$t('common.close')"
        :okText="$t('common.save')"
        @ok="handleBaseInfoModalOk"
      >
        <a-form
          ref="refForm"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 10 }"
          :rules="rules"
          :model="baseInfoModal"
        >
          <a-form-item
            name="destinationType"
            :label="$t('warehouse.destination_type')"
          >
            <a-select
              v-model:value="baseInfoModal.destinationType"
              @change="handelChangeDestinationType"
              :placeholder="$t('warehouse.destination_type')"
            >
              <a-select-option
                v-for="(item, index) in dropdownData.destinationTypes"
                :key="index"
                :value="item"
                :title="$t($enumLangkey('destinationType', item))"
              >
                {{ $t($enumLangkey("destinationType", item)) }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            name="customerNo"
            :label="$t('warehouse.customer_number')"
          >
            <a-input
              autoComplete="off"
              v-model:value="baseInfoModal.customerNo"
              :placeholder="$t('warehouse.customer_number')"
              allow-clear
            />
          </a-form-item>

          <a-form-item name="countryId" :label="$t('warehouse.source_country')">
            <a-select
              v-model:value="baseInfoModal.countryId"
              :loading="dropdownData.sourceCountryLoading"
              :allowClear="true"
              show-search
              optionFilterProp="search-key"
              :placeholder="$t('common.country')"
            >
              <a-select-option
                v-for="item in dropdownData.sourceCountrys"
                :key="item.countryId"
                :value="item.countryId"
                :info="item"
                :title="getLanguageName(item)"
                :search-key="
                  item.countryCnName + item.countryEnName + item.ioS2
                "
              >
                {{ getLanguageName(item) }}({{ item.ioS2 }})
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="
              baseInfoModal.destinationType &&
              baseInfoModal.destinationType != destinationTypeEnum.OtherAddress
            "
            name="toWarehoueOrFbaOrMcdId"
            :label="$t('warehouse.warehouse_code')"
          >
            <a-select
              v-model:value="baseInfoModal.toWarehoueOrFbaOrMcdId"
              @change="handleChangeWarehouseCodes"
              :loading="dropdownData.warehouseCodesLoading"
              :allowClear="true"
              show-search
              optionFilterProp="search-key"
              :placeholder="$t('warehouse.warehouse_code')"
            >
              <!-- se 枚举值==1-->
              <template
                v-if="
                  baseInfoModal.destinationType ==
                  destinationTypeEnum.SeWarehouse
                "
              >
                <a-select-option
                  v-for="(item, index) in dropdownData.warehouseCodes"
                  :key="index"
                  :info="item"
                  :code="item.warehouseNo"
                  :value="item.id"
                  :search-key="item.warehouseNo + item.warehouseName"
                  :title="item.warehouseNo + '(' + item.warehouseName + ')'"
                >
                  {{ item.warehouseNo
                  }}<span v-if="item.warehouseName">
                    ({{ item.warehouseName }})</span
                  >
                </a-select-option>
              </template>
              <!-- fba枚举值 ==2-->
              <template
                v-if="baseInfoModal.destinationType == destinationTypeEnum.FBA"
              >
                <a-select-option
                  v-for="(item, index) in dropdownData.warehouseCodes"
                  :key="index"
                  :code="item.name"
                  :info="item"
                  :value="item.id"
                  :search-key="item.name"
                  :title="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </template>
            </a-select>
          </a-form-item>

          <!-- <a-row v-if="baseInfoModal.toWarehoueOrFbaOrMcdId">
            <a-col :span="24" :offset="5" class="mb-4">
              <a-row :gutter="[24, 8]">
                <a-col>
                  {{ $t("warehouse.recipients") }}:
                  {{ baseInfoModal?.showRecipients }}
                </a-col>
                <a-col>
                  {{ $t("warehouse.recipient_company") }}:
                  {{ baseInfoModal?.showRecipientsCompany }}
                </a-col>
                <a-col :span="24">
                  {{ $t("warehouse.receive_goods_address") }}:
                  {{ getAddress(baseInfoModal?.showRecipientsAddress || {}) }}
                </a-col>
              </a-row>
            </a-col>
          </a-row> -->
          <a-form-item
            name="tranPlanCharacteristics"
            :label="$t('warehouse.transport_characteristics')"
          >
            <a-select
              v-model:value="baseInfoModal.tranPlanCharacteristics"
              mode="multiple"
              :allowClear="true"
              :placeholder="$t('warehouse.transport_characteristics')"
            >
              <a-select-option
                v-for="(item, index) in transportCharacteristicsEnum"
                :key="index"
                :value="item"
                :title="$t($enumLangkey('transportCharacteristics', item))"
              >
                {{ $t($enumLangkey("transportCharacteristics", item)) }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>

      <a-modal
        v-model:visible="boxModal.visible"
        :title="$t('warehouse.edit_box_info')"
        :cancelText="$t('common.close')"
        :okText="$t('common.confirm')"
        :confirm-loading="boxModal.loading"
        :maskClosable="false"
        @ok="handleBoxModalOk"
        :centered="true"
      >
        <a-row :gutter="[0, 8]">
          <a-col :span="24">
            <a-row type="flex" :gutter="[0, 4]">
              <a-col :span="24">
                <span>{{ $t("warehouse.weight") }}: </span>
                <a-input-number
                  v-model:value="boxModal.weight"
                  :precision="3"
                  :placeholder="$t('warehouse.weight')"
                  class="ml-2"
                  :min="0"
                />
                kg
              </a-col>

              <a-col>
                <span>{{ $t("warehouse.size") }}: </span>
                <a-input-number
                  v-model:value="boxModal.length"
                  :step="1"
                  :precision="0"
                  class="ml-2 mt-2"
                  :placeholder="$t('common.max_length_side')"
                  :min="1"
                />
                <a-input-number
                  v-model:value="boxModal.width"
                  :step="1"
                  :precision="0"
                  class="ml-2 mt-2"
                  :placeholder="$t('common.second_length_side')"
                  :min="1"
                />
                <a-input-number
                  v-model:value="boxModal.height"
                  :step="1"
                  :precision="0"
                  class="ml-2 mt-2"
                  :placeholder="$t('common.least_length_side')"
                  :min="1"
                />
                cm
              </a-col>

              <a-col :span="24">
                <span>{{ $t("warehouse.box_amount") }}: </span>
                <a-input-number
                  v-model:value="boxModal.boxCount"
                  :precision="0"
                  :placeholder="$t('warehouse.box_amount')"
                  class="ml-2 mt-2"
                  :min="0"
                />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal
        v-model:visible="data.importConfirmModlaVisible"
        :centered="true"
        :maskClosable="false"
        :footer="null"
      >
        <div class="text-center mt-4">
          <strong>
            {{
              $t("warehouse.the_quantity_of_batch_import_is_p0", [
                data?.list?.length,
              ])
            }}
          </strong>
        </div>

        <a-row type="flex" justify="center" class="mt-4">
          <a-col>
            <a-button
              @click="data.importConfirmModlaVisible = false"
              :loading="data.loading"
            >
              {{ $t("common.cancel") }}
            </a-button>
            <a-button
              class="ml-5"
              type="primary"
              :disabled="funcWhetherCanImport()"
              :loading="data.loading"
              @click="hanldeImportConfirm"
            >
              {{ $t("common.confirm_the_import") }}
            </a-button>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal
        v-model:visible="errorMsgModal.visible"
        :centered="true"
        :maskClosable="false"
        :footer="null"
      >
        <div class="text-center mt-4">
          <strong>
            <span>
              {{
                $t("common.the_number_of_successes_is_p0", [
                  " " + errorMsgModal.succeedCount,
                ])
              }}
            </span>
            <span v-if="errorMsgModal.failCount > 0"> , </span>
            <span class="text-error" v-if="errorMsgModal.failCount > 0">
              <!-- 失败数量为 {{errorMsgModal.failCount}} -->
              {{
                $t("common.the_number_of_fail_is_p0", [
                  " " + errorMsgModal.failCount,
                ])
              }}
            </span>
          </strong>
        </div>
        <div v-if="errorMsgModal.failCount > 0">
          <span> {{ $t("common.fail_order") }} </span>:
          <div
            class="p-2 messageCard"
            v-if="errorMsgModal?.errObjs?.length > 0"
          >
            <div
              class="mt-2"
              v-for="(item, index) in errorMsgModal?.errObjs"
              :key="index"
            >
              <span>
                {{ index + 1 }}. [{{ item.relationOrderNo }}]:
                {{ $t(`error.${item?.errCode}`) }}
              </span>
            </div>
          </div>
        </div>

        <a-row type="flex" justify="center" class="mt-4">
          <a-col>
            <a-button
              type="primary"
              @click="errorMsgModal.visible = !errorMsgModal.visible"
            >
              {{ $t("common.close") }}
            </a-button>
          </a-col>
        </a-row>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  onActivated,
  onMounted,
  reactive,
  toRefs,
  ref,
  nextTick,
} from "vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import {
  Row,
  Col,
  Input,
  Space,
  Select,
  DatePicker,
  Button,
  Table,
  Dropdown,
  Menu,
  Spin,
  Modal,
  Upload,
  message,
  Popconfirm,
  Popover,
  Descriptions,
  Checkbox,
  Form,
  InputNumber,
  Card,
} from "ant-design-vue";
import {
  gToKg,
  cmCubicToM,
  getAddressByLanguageV2,
  getName,
} from "@/utils/general";
import { useI18n } from "vue-i18n/index";
import {} from "@/api/modules/printWaybill/create";
import {
  parseFile,
  joinDownloadTemplateUrl,
  validationTableData,
  getSupplierByBatchWayBill,
  getSupplierByBatchWayBillDetail,
  createBatchWayBill,
} from "@/api/modules/printWaybill/batchImport";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  destinationType as destinationTypeEnum,
  transportCharacteristics as transportCharacteristicsEnum,
  waybillOperationStatus as waybillOperationStatusEnum,
} from "@/enum/enum.json";
import localPrint from "@/utils/localPrint";
import { downloadFile } from "@/utils/downloader";
import { maxUploadFileSizeByte } from "@/utils/common";
import CAddressModal from "./components/CAddressModal.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import {
  getSourceCountrys,
  getSeTargetWarehouseInfos,
  getFbaTargetWarehouseInfos,
} from "../../api/modules/printWaybill/create";
import { getErrorMsgKey } from "@/api/errorHandlers";
import { kgToG } from "../../utils/general";

export default defineComponent({
  name: "print_waybill_batch_import",
  components: {
    CSignSupported,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpin: Spin,
    AInputNumber: InputNumber,
    AInputGroup: Input.Group,
    Content,
    CPager,
    AUpload: Upload,
    CTrackInfoModal,
    APopconfirm: Popconfirm,
    APopover: Popover,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    CAddressModal,
    AModal: Modal,
    ACheckbox: Checkbox,
    AFormItem: Form.Item,
    AForm: Form,
    ACard: Card,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();

    const data = reactive({
      list: [],
      loading: false,
      createLoading: false,
      importConfirmModlaVisible: false,
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t("warehouse.serial_number"),
        // customRender: ({ text, record, index }) => {
        //   return index + 1;
        // },
        slots: {
          customRender: "serial_number",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.customer_number"),
        fixed: "left",
        slots: {
          customRender: "orderNo",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.originating_country"),
        slots: {
          customRender: "originating_country",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.destination_type"),
        slots: {
          customRender: "destinationType",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.warehouse_code"),
        slots: {
          customRender: "warehouse_code",
        },
      },

      {
        width: 100,
        title: () =>
          vueI18n.t("logistics.recipients") +
          "/" +
          vueI18n.t("warehouse.phone"),
        slots: {
          customRender: "recipientsAndPhone",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.destination_address"),
        slots: {
          customRender: "destination_address",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.transport_characteristics"),
        slots: {
          customRender: "transport_characteristics",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.channel"),
        slots: {
          customRender: "transport_scheme",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("common.operation"),
        fixed: "right",
        slots: {
          customRender: "delete",
        },
      },
    ];

    const innerColumnsImport = [
      {
        title: () => vueI18n.t("warehouse.actual_weight"),
        width: 150,
        slots: {
          customRender: "weight",
        },
      },
      {
        title: () => vueI18n.t("logistics.size"),
        width: 150,
        slots: {
          customRender: "size",
        },
      },
      {
        title: () => vueI18n.t("warehouse.girth"),
        width: 150,
        slots: {
          customRender: "girth",
        },
      },
      {
        title: () => vueI18n.t("warehouse.volume"),
        width: 150,
        slots: {
          customRender: "volume",
        },
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("common.hint"),
        slots: {
          customRender: "errorMsg",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("common.operation"),
        slots: {
          customRender: "deleteProduct",
        },
        width: 100,
      },
    ];

    const getLanguageName = (item) => {
      if (item.countryCnName && item.countryEnName) {
        return getName(
          {
            cnName: item.countryCnName,
            enName: item.countryEnName,
          },
          getters.language
        );
      }
      return getName(item, getters.language);
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    //下载模板文件
    const hanldeDownloadTemplateFile = () => {
      data.downloadLoading = true;
      let url = joinDownloadTemplateUrl();
      downloadFile(url, "Print waybill template file.xlsx");
      data.downloadLoading = false;
    };

    //上传文件并解析
    const handleParseFile = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte()) {
        message.error(
          vueI18n.t("common.please_upload_file_with_size_less_than_50MB")
        );
        return;
      }
      message.loading({
        content: "解析文件中...",
        key: "Parse",
        duration: 2,
      });
      data.loading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parseFile(parameter)
        .then(({ result }) => {
          _mapList(result);
          data.loading = false;
        })
        .catch((res) => {
          data.listVisible = false;
          data.loading = false;
        });
    };

    const _mapList = (result) => {
      if (result) {
        let list = result;
        list.forEach((x, index) => {
          let itemWhetherCanImport = !(
            !x.isNormal || x.boxes.findIndex((p) => !p.isNormal) >= 0
          );
          //一条数据是能导入 (无异常(包含产品))
          x.whetherCanImport = itemWhetherCanImport;
          x.id = x.customerNo + index;
          x.isShowBoxError = x.boxes?.findIndex((x) => !x?.isNormal) >= 0;
          x.boxes?.forEach((b) => (b.parentId = x.id));
        });
        data.list = list;
      } else {
        data.list = null;
      }
      data.listVisible = true;
    };

    const hanldeDeleteData = (index) => {
      data.list = data.list.filter((x, i) => i != index);
      funcValidationTableData();
    };

    const hanldeDeleteBoxData = (record, index) => {
      let targetOrder = data.list.find((x) => x.id == record.parentId);
      if (targetOrder?.boxes?.length <= 1) {
        message.error(
          vueI18n.t("warehouse.at_least_one_box_of_data_is_required")
        );
        return;
      } else {
        let boxes = targetOrder?.boxes?.filter((x, i) => i != index);
        targetOrder.boxes = boxes;
        funcValidationTableData();
      }
    };

    //-----------------重新地址 start------------------------
    const addressModal = reactive({
      addressData: {
        selectedCommonAddress: null,
        // commonAddressName: "",//发自\发至
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: null,
        detailedAddress2: null,
        postCode: "",
        contactPhone: null,
        phoneCountryId: null,
        receiptUserName: null,
      },
      currentLogisticInfo: null,
      visible: false,
    });

    const hanldeShowAddressModal = (record) => {
      addressModal.addressData.selectedCommonAddress = record.to?.other?.id;

      addressModal.addressData.selectedCountrys =
        record.to?.other?.countryId == 0 ? null : record.to?.other?.countryId;
      addressModal.addressData.selectedProvinces = record.to?.other?.provinceId;
      addressModal.addressData.selectedCity = record.to?.other?.cityId;
      addressModal.addressData.selectedCounty = record.to?.other?.countyId;

      addressModal.addressData.detailedAddress = record.to?.other?.address;
      addressModal.addressData.detailedAddress2 = record.to?.other?.address2;
      addressModal.addressData.postCode = record.to?.other?.postCode;

      addressModal.addressData.contactPhone = record.to?.other?.telephone;
      addressModal.addressData.phoneCountryId =
        record.to?.other?.telephoneCountryId == 0
          ? null
          : record.to?.other?.telephoneCountryId;
      addressModal.addressData.receiptUserName = record.to?.other?.name;
      addressModal.addressData.receiptCompany = record.to?.other?.companyName;
      addressModal.visible = true;
      addressModal.record = record;
    };

    const handleAlterAddress = (address) => {
      addressModal.record.to.other.name = address.receiptUserName;
      addressModal.record.to.other.companyName = address.receiptCompany;
      addressModal.record.to.other.telephoneCountryTelCode = address.areaCode;
      addressModal.record.to.other.telephone = address.contactPhone;

      addressModal.record.to.other.countryIOS2Name = address.ioS2;
      addressModal.record.to.other.provinceAbbrName = address.abbrCode;
      addressModal.record.to.other.cityEnName = address.cityEnName;

      addressModal.record.to.other.address = address.detailedAddress;
      addressModal.record.to.other.address2 = address.detailedAddress2;
      addressModal.record.to.other.postCode = address.postCode;
      funcValidationTableData();
    };

    //修改地址或更改箱,或删除后验证 table中数据
    const funcValidationTableData = () => {
      if (!data.list || data.list?.length == 0) {
        return;
      }
      let pra = [];
      data.list?.forEach((x) => {
        x.boxes.forEach((r) => {
          let item = {
            customerNo: x.customerNo,
            fromISO2: x.from?.ioS2,
            tranPlanCharacteristics: x.tranPlanCharacteristics?.join(","),

            channelCode: x.supplierLogisticScheme?.logisticsBaseInfo?.code,
            vat: x.supplierLogisticScheme?.logisticsRegionInfo?.vatCode,
            isChooseDDP:
              x.supplierLogisticScheme?.logisticsRegionInfo?.isChooseDdp,
            signatureType:
              x.supplierLogisticScheme?.logisticsRegionInfo?.signatureType,

            destinationType: x.destinationType,
            toSEWarehouseNo: x.to.seWarehouse?.warehouseNo,
            toFBAWarehouseNo: x.to.fbaWarehouse?.name,
            length: r.length,
            width: r.width,
            height: r.height,
            weight: r.weight,
            count: r.boxCount,
          };

          if (x.destinationType == destinationTypeEnum.OtherAddress) {
            item.toOtherReceipt = x.to?.other?.name;
            item.toOtherReceiptCompanyName = x.to?.other?.companyName;
            item.toOtherAreaCode = x.to?.other?.telephoneCountryTelCode;
            item.toOtherPhoneNumber = x.to?.other?.telephone;
            item.toOtherISO2 = x.to?.other?.countryIOS2Name;
            item.toOtherAbbrCode = x.to?.other?.provinceAbbrName;
            item.toOtherCityEnName = x.to?.other?.cityEnName;
            item.toOtherAddress = x.to?.other?.address;
            item.toOtherAddress2 = x.to?.other?.address2;
            item.toOtherPostCode = x.to?.other?.postCode;
          }
          pra.push(item);
        });
      });

      data.loading = true;
      validationTableData(pra)
        .then(({ result }) => {
          // 重组页面数据
          _mapList(result);
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    //-----------------重新地址 end------------------------------------------------------------------------

    //-----------------重新选择物流 start------------------------------------------------------------------------
    // 物流
    const logisticsColumns = [
      {
        title: "",
        id: "optionCustom",
        slots: {
          customRender: "optionCustom",
        },
        width: 100,
      },
      {
        title: () =>
          vueI18n.t("warehouse.logistics_scheme") +
          "/" +
          vueI18n.t("warehouse.logistics_code"),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.reference_aging"),
        slots: {
          customRender: "referenceAgingCustom",
        },
        width: 150,
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.freight_estimate"),
        slots: {
          customRender: "freightsCustom",
        },
      },
      {
        title: () => vueI18n.t("common.details"),
        slots: {
          customRender: "detailsCustom",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.optional_services"), //可选服务
        slots: {
          customRender: "optionalServicesCustom",
        },
        width: 230,
      },
    ];

    const logistics = reactive({
      loading: false,
      visible: false,
      selectedLogisticsSchemeId: null,
      currentLogisticInfo: null, //当前正在修改的订单的物流方案信息
    });

    //显示物流modal
    const hanldeShowLogisticsModal = (record) => {
      if (!record.destinationType) {
        message.error(
          vueI18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            vueI18n.t("warehouse.destination_type"),
          ])
        );
        return;
      }

      if (!record.from) {
        message.error(
          vueI18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            vueI18n.t("warehouse.originating_country"),
          ])
        );
        return;
      }

      if (
        !record.to.seWarehouse &&
        !record.to.fbaWarehouse &&
        !record.to.other
      ) {
        message.error(
          vueI18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            vueI18n.t("logistic.destination_address"),
          ])
        );
        return;
      }

      if (
        !record.tranPlanCharacteristics ||
        record.tranPlanCharacteristics?.length == 0
      ) {
        message.error(
          vueI18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            vueI18n.t("warehouse.transport_characteristics"),
          ])
        );
        return;
      }

      // 验证箱是否异常---todo优化 用some
      let isBoxError = null;
      try {
        record.boxes?.forEach((x) => {
          if (!x.isNormal) {
            isBoxError = true;
            throw Error();
          }
        });
      } catch (error) {
        //nothing
      }

      if (isBoxError) {
        message.error(
          vueI18n.t("warehouse.p0_is_abnormal_and_cannot_be_modified", [
            vueI18n.t("warehouse.box"),
          ])
        );
        return;
      }

      logistics.visible = true;
      logistics.currentLogisticInfo = record;
      logistics.columns = logisticsColumns;
      logistics.selectedLogisticsSchemeId = null;
      logistics.list = [];

      let pra = {
        destinationType: record.destinationType,
        transportCharacteristics: record.tranPlanCharacteristics,
        from: { countryId: record.from.countryId },
        items: record.boxes.map((x) => {
          return {
            length: x.length,
            width: x.width,
            height: x.height,
            weight: kgToG(x.weight),
            count: x.boxCount,
          };
        }),
        to: {
          seWarehouse: null,
          fbaWarehouse: null,
          other: null,
        },
      };

      if (record.destinationType == destinationTypeEnum.SeWarehouse) {
        pra.to.seWarehouse = {};
        pra.to.seWarehouse.warehouseId = record.to.seWarehouse?.id;
      }

      if (record.destinationType == destinationTypeEnum.FBA) {
        pra.to.fbaWarehouse = {};
        pra.to.fbaWarehouse.warehouseId = record.to.fbaWarehouse?.id;
      }

      if (record.destinationType == destinationTypeEnum.OtherAddress) {
        pra.to.other = {};
        pra.to.other.countryId = record.to.other.countryId;
        pra.to.other.provinceId = record.to.other.provinceId;
        pra.to.other.cityId = record.to.other.cityId;
        pra.to.other.address = record.to.other.address;
        pra.to.other.address2 = record.to.other.address2;
        pra.to.other.postCode = record.to.other.postCode;
      }
      logistics.searchListParameter = pra;
      logistics.loading = true;
      getSupplierByBatchWayBill(pra)
        .then((res) => {
          if (res.result && res.result?.length > 0) {
            logistics.list = res.result.map((x, index) => {
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight
                  ? gToKg(x.logisticsBaseInfo.chargeableWeight)
                  : "0", //计费重
                chargeableVolume: cmCubicToM(
                  x.logisticsBaseInfo.chargeableVolume
                ),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
                declareSupported: x.logisticsBaseInfo.declareSupported, //是否支持一般贸易报关
                logisticsRegionInfo: x.logisticsRegionInfo, //是否支持DDP和签字

                loading: false,
              };
            });
            logistics.columns = logisticsColumns;
          } else {
            logistics.list = [];
          }
          logistics.loading = false;
        })
        .catch((r) => {
          logistics.loading = false;
        });
    };

    //选择物流(获取某个物流的详情,更新签名签收)--模板计算
    const handleSelectLogistics = (record) => {
      logistics.selectedLogisticsSchemeId = record.id;
      let pra = {
        supplierId: logistics.selectedLogisticsSchemeId,
        ...logistics.searchListParameter,
      };
      logistics.loading = true;
      getSupplierByBatchWayBillDetail(pra)
        .then((res) => {
          logistics.columns = logisticsColumns.filter(
            (x) => x.id != "optionCustom"
          );
          logistics.list = [];
          let r = [res.result.logistic];
          logistics.list = r?.map((x, index) => {
            return {
              key: index,
              id: x.logisticsBaseInfo.id,
              scheme: x.logisticsBaseInfo.name,
              code: x.logisticsBaseInfo.code,
              descriptionItems: x.logisticsBaseInfo.descriptionItems,

              logisticsWay: x.logisticsBaseInfo.logisticsType,
              deliveryWay: x.logisticsBaseInfo.deliveryType,

              daysMin: x.logisticsBaseInfo.daysMin,
              daysMax: x.logisticsBaseInfo.daysMax,
              channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

              averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
              expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
              currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

              chargeableWeight: gToKg(x.logisticsBaseInfo.chargeableWeight), //计费重
              volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
              chargeableVolume: cmCubicToM(
                x.logisticsBaseInfo.chargeableVolume
              ),
              logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,

              logisticsRegionInfo: x.logisticsRegionInfo,
            };
          });
          logistics.loading = false;
        })
        .catch((e) => {
          logistics.loading = false;
        });
    };

    //确定物流 - 模板计算
    const handleConfirmLogistics = () => {
      logistics.currentLogisticInfo.supplierLogisticScheme = {
        logisticsRegionInfo: {
          vatCode: null,
        },
        logisticsBaseInfo: {},
      };

      logistics.visible = false;
      let vatCode = null;
      if (
        logistics.currentLogisticInfo.supplierLogisticScheme
          ?.logisticsRegionInfo?.vatCode
      ) {
        vatCode =
          logistics.currentLogisticInfo.supplierLogisticScheme
            ?.logisticsRegionInfo?.vatCode;
      }

      logistics.currentLogisticInfo.supplierLogisticScheme.logisticsRegionInfo =
        logistics.list[0].logisticsRegionInfo; // 选了ddp和签字签收,这个对象里面才有这个字段,不然没有这个字段.

      logistics.currentLogisticInfo.supplierLogisticScheme.logisticsRegionInfo.vatCode =
        vatCode; //不清除原来的vat

      logistics.currentLogisticInfo.supplierLogisticScheme.logisticsBaseInfo.id =
        logistics.list[0].id;
      logistics.currentLogisticInfo.supplierLogisticScheme.logisticsBaseInfo.name =
        logistics.list[0].scheme;
      logistics.currentLogisticInfo.supplierLogisticScheme.logisticsBaseInfo.code =
        logistics.list[0].code ?? null;
      logistics.currentLogisticInfo.supplierLogisticScheme.logisticsBaseInfo.deliveryType =
        logistics.list[0].deliveryWay;
      funcValidationTableData();
    };

    //-----------------重新选择物流 end------------------------------------------------------------------------

    //-----------------修改基本信息(客户单号\始发国家\目的地类型\仓库代码\运输特征) start------------------------------------------------------------------------
    const dropdownData = reactive({
      destinationTypes: [
        destinationTypeEnum.SeWarehouse,
        destinationTypeEnum.FBA,
        destinationTypeEnum.OtherAddress,
      ],
      sourceCountrys: [],
      sourceCountryLoading: false,
      warehouseCodes: [],
      warehouseCodesLoading: false,
    });

    const refForm = ref();
    const baseInfoModal = reactive({
      visible: false,
      loading: false,

      destinationType: null,
      customerNo: null,
      countryId: null,
      ioS2: null,
      toWarehoueOrFbaOrMcdId: null,
      warehouseNo: null,
      tranPlanCharacteristics: [],

      // showRecipients: null,
      // showRecipientsCompany: null,
      // showRecipientsAddress: null,

      record: null,
    });

    const rules = {
      destinationType: [
        {
          required: true,
          type: "number",
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.destination_type"),
            ]);
          },
        },
      ],
      customerNo: [
        {
          required: true,
          trigger: ["change", "blur"],
          pattern: /^[A-Za-z0-9\-]{5,15}$/,
          message: vueI18n.t("warehouse.please_enter_the_correct_p0", [
            vueI18n.t("warehouse.customer_number"),
          ]),
        },
      ],
      countryId: [
        {
          required: true,
          whitespace: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.source_country"),
            ]);
          },
        },
      ],
      toWarehoueOrFbaOrMcdId: [
        {
          required: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.warehouse_code"),
            ]);
          },
        },
      ],
      tranPlanCharacteristics: [
        {
          required: true,
          whitespace: true,
          type: "array",
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.transport_characteristics"),
            ]);
          },
        },
      ],
    };

    //始发国家
    const funcGetSourceCountrys = () => {
      dropdownData.sourceCountryLoading = true;
      getSourceCountrys()
        .then(({ result }) => {
          if (result) {
            dropdownData.sourceCountrys = result;
          }
          dropdownData.sourceCountryLoading = false;
        })
        .catch(() => {
          dropdownData.sourceCountryLoading = false;
        });
    };

    const handleShowBaseInfoModal = (record) => {
      baseInfoModal.visible = true;
      if (dropdownData.sourceCountrys.length == 0) {
        funcGetSourceCountrys();
      }
      baseInfoModal.record = record;
      baseInfoModal.destinationType = dropdownData.destinationTypes.includes(
        record.destinationType
      )
        ? record.destinationType
        : null;
      baseInfoModal.customerNo = record.customerNo;
      baseInfoModal.countryId = record.from?.countryId;

      baseInfoModal.tranPlanCharacteristics =
        record.tranPlanCharacteristics ?? [];
      refForm.value?.clearValidate();
      handelChangeDestinationType();
      baseInfoModal.toWarehoueOrFbaOrMcdId = null;
      if (record.destinationType == destinationTypeEnum.SeWarehouse) {
        baseInfoModal.toWarehoueOrFbaOrMcdId = record.to?.seWarehouse?.id;
        baseInfoModal.warehouseNo = record.to?.seWarehouse?.warehouseNo;
      }
      if (record.destinationType == destinationTypeEnum.FBA) {
        baseInfoModal.toWarehoueOrFbaOrMcdId = record.to?.fbaWarehouse?.id;
        baseInfoModal.warehouseNo = record.to?.fbaWarehouse?.name;
      }
    };

    const handleBaseInfoModalOk = () => {
      refForm.value.validate().then(() => {
        //赋值并重新验证页面数据
        baseInfoModal.record.destinationType = baseInfoModal.destinationType;
        baseInfoModal.record.customerNo = baseInfoModal.customerNo;
        baseInfoModal.record.countryId = baseInfoModal.countryId;
        baseInfoModal.record.from.ioS2 = dropdownData.sourceCountrys?.find(
          (x) => x.countryId == baseInfoModal.countryId
        )?.ioS2;
        if (baseInfoModal.destinationType == destinationTypeEnum.SeWarehouse) {
          if (!baseInfoModal.record.to.seWarehouse) {
            baseInfoModal.record.to.seWarehouse = {};
          }
          baseInfoModal.record.to.seWarehouse.warehouseNo =
            baseInfoModal.warehouseNo;
        }
        if (baseInfoModal.destinationType == destinationTypeEnum.FBA) {
          if (!baseInfoModal.record.to.fbaWarehouse) {
            baseInfoModal.record.to.fbaWarehouse = {};
          }
          baseInfoModal.record.to.fbaWarehouse.name = baseInfoModal.warehouseNo;
        }

        baseInfoModal.record.tranPlanCharacteristics =
          baseInfoModal.tranPlanCharacteristics;
        baseInfoModal.visible = false;
        //重新验证
        funcValidationTableData();
      });
    };

    const handelChangeDestinationType = () => {
      if (baseInfoModal.destinationType == destinationTypeEnum.SeWarehouse) {
        hanldeGetSeTargetWarehouseInfos();
      } else if (baseInfoModal.destinationType == destinationTypeEnum.FBA) {
        hanldeGetFbaTargetWarehouseInfos();
      }
      baseInfoModal.toWarehoueOrFbaOrMcdId = null;
    };

    //获得se 仓库代码
    const hanldeGetSeTargetWarehouseInfos = () => {
      dropdownData.warehouseCodesLoading = true;
      getSeTargetWarehouseInfos()
        .then(({ result }) => {
          if (result) {
            dropdownData.seWarehouseCodes = result;
            dropdownData.warehouseCodes = result;
            dropdownData.warehouseCodesLoading = false;
          }
        })
        .catch(() => {
          dropdownData.warehouseCodesLoading = false;
        });
    };

    //获得fba 仓库代码
    const hanldeGetFbaTargetWarehouseInfos = () => {
      dropdownData.warehouseCodesLoading = true;
      getFbaTargetWarehouseInfos()
        .then(({ result }) => {
          if (result) {
            dropdownData.fbaWarehouseCodes = result;
            dropdownData.warehouseCodes = result;
            dropdownData.warehouseCodesLoading = false;
          }
        })
        .catch(() => {
          dropdownData.warehouseCodesLoading = false;
        });
    };

    //仓库代码()
    const handleChangeWarehouseCodes = (value, option) => {
      baseInfoModal.warehouseNo = option.code;
      // if (!value) {
      //   baseInfoModal.showRecipients = null;
      //   baseInfoModal.showRecipientsCompany = null;
      //   baseInfoModal.showRecipientsAddress = null;
      //   return;
      // }

      // if (baseInfoModal.destinationType == destinationTypeEnum.SeWarehouse) {
      //   baseInfoModal.showRecipients = "SALEASY";
      //   baseInfoModal.showRecipientsCompany = option.code;
      // } else if (baseInfoModal.destinationType == destinationTypeEnum.FBA) {
      //   baseInfoModal.showRecipients = "AMAZON.COM SERVICES INC";
      //   baseInfoModal.showRecipientsCompany = option.code;
      // }
      // baseInfoModal.showRecipientsAddress = option.info;
    };

    //-----------------修改基本信息(客户单号\始发国家\目的地类型\仓库代码\运输特征) end------------------------------------------------------------------------

    //-----------------修改箱尺寸 start------------------------------------------------------------------------
    const boxModal = reactive({
      visible: false,
      loading: false,
      weight: null,
      length: null,
      width: null,
      height: null,
      boxCount: null,
      record: null,
    });

    const handleShowBoxModal = (record) => {
      boxModal.visible = true;
      boxModal.record = record;
      boxModal.weight =
        !record.weight || record.weight <= 0 ? null : record.weight;
      boxModal.length =
        !record.length || record.length <= 0 ? null : record.length;
      boxModal.width = !record.width || record.width <= 0 ? null : record.width;
      boxModal.height =
        !record.height || record.height <= 0 ? null : record.height;
      boxModal.boxCount =
        !record.boxCount || record.boxCount <= 0 ? null : record.boxCount;
    };

    const handleBoxModalOk = () => {
     
      if (!boxModal.weight || boxModal.weight == 0) {
        return message.error(
          vueI18n.t("common.p0_cannot_be_empty_or_0", [
            vueI18n.t("warehouse.weight"),
          ])
        );
      }

      if (
        !boxModal.length ||
        boxModal.length == 0 ||
        !boxModal.width ||
        boxModal.width == 0 ||
        !boxModal.height ||
        boxModal.height == 0
      ) {
        return message.error(
          vueI18n.t("common.p0_cannot_be_empty_or_0", [
            vueI18n.t("warehouse.size"),
          ])
        );
      }

      if (!boxModal.boxCount || boxModal.boxCount == 0) {
        return message.error(
          vueI18n.t("common.p0_cannot_be_empty_or_0", [
            vueI18n.t("warehouse.box_amount"),
          ])
        );
      }

      Object.assign(boxModal.record, boxModal);
      boxModal.visible = false;
      funcValidationTableData();
    };

    //-----------------修改箱尺寸 end------------------------------------------------------------------------

    //导入前验证vat, 图片，派送方式和 数据是否异常
    const _verificationData = () => {
      let passed = true;
      data.list.some((x) => {
        if (
          x?.supplierLogisticScheme?.logisticsRegionInfo?.isVatRequired &&
          (!x?.supplierLogisticScheme?.logisticsRegionInfo?.vatCode ||
            x?.supplierLogisticScheme?.logisticsRegionInfo?.vatCode?.trim()
              ?.length == 0)
        ) {
          message.error(vueI18n.t("common.p0_is_required", ["VAT"]));
          passed = false;
          return true;
        }
      });
      return passed;
    };

    //导入按钮=>显示导入数量modal
    const hanldeShowImporMessage = () => {
      if (!_verificationData()) {
        return;
      }
      data.importConfirmModlaVisible = true;
    };

    // 确认导入
    const hanldeImportConfirm = () => {
      data.loading = true;
      let parameter = [];

      data.list.forEach((x) => {
        if (x.isNormal) {
          let tempData = null;
          tempData = {
            supplier: {
              supplierId: x.supplierLogisticScheme.logisticsBaseInfo.id,
              chooseDdp:
                x.supplierLogisticScheme.logisticsRegionInfo.isChooseDdp,
              chooseSignSupported: x.supplierLogisticScheme.logisticsRegionInfo
                .signatureType
                ? true
                : false,
              signatureType:
                x.supplierLogisticScheme.logisticsRegionInfo.signatureType,
              vatCode: x.supplierLogisticScheme.logisticsRegionInfo.vatCode,
            },
            details: x.boxes.map((r) => {
              return {
                count: r.boxCount,
                length: r.length,
                width: r.width,
                height: r.height,
                weight: kgToG(r.weight),
              };
            }),
            customerNo: x.customerNo,
            destinationType: x.destinationType,
            tranPlanCharacteristics: x.tranPlanCharacteristics,
            from: { countryId: x.from.countryId },
            to: {
              seWarehouse: null,
              fbaWarehouse: null,
              other: null,
            },
          };

          if (x.destinationType == destinationTypeEnum.SeWarehouse) {
            tempData.to.seWarehouse = {};
            tempData.to.seWarehouse.warehouseId = x.to.seWarehouse?.id;
          }

          if (x.destinationType == destinationTypeEnum.FBA) {
            tempData.to.fbaWarehouse = {};
            tempData.to.fbaWarehouse.warehouseId = x.to.fbaWarehouse?.id;
          }

          if (x.destinationType == destinationTypeEnum.OtherAddress) {
            tempData.to.other = {};
            tempData.to.other.companyName = x.to.other.companyName;
            tempData.to.other.telephone = x.to.other.telephone;
            tempData.to.other.telephoneCountryId =
              x.to.other.telephoneCountryId;
            tempData.to.other.name = x.to.other.name;
            tempData.to.other.countryId = x.to.other.countryId;
            tempData.to.other.provinceId = x.to.other.provinceId;
            tempData.to.other.cityId = x.to.other.cityId;
            tempData.to.other.countyId = x.to.other.countyId;
            tempData.to.other.address = x.to.other.address;
            tempData.to.other.address2 = x.to.other.address2;
            tempData.to.other.postCode = x.to.other.postCode;
          }
          parameter.push(tempData);
        }
      });

      //确认导入
      createBatchWayBill({ items: parameter })
        .then(({ result }) => {
          if (!result.failCount || result.failCount == 0) {
            //失败数量为0,就隐藏解析文件table
            data.listVisible = false;
          }
          errorMsgModal.visible = true;
          errorMsgModal.succeedCount = result?.succeedCount ?? 0;
          errorMsgModal.failCount = result?.failCount ?? 0;
          errorMsgModal.errObjs = result.errObjs;

          data.loading = false;
          data.importConfirmModlaVisible = false;
        })
        .catch(() => {
          data.loading = false;
          data.importConfirmModlaVisible = false;
        });
    };

    //判断整个table是否能导入 (页面无错误数据（包括产品）为true,可导入)
    const funcWhetherCanImport = () => {
      return (
        data.list.length == 0 ||
        data.list.findIndex((p) => !p.whetherCanImport) >= 0
      );
    };

    //-----------------错误消息 start-------------

    const errorMsgModal = reactive({
      visible: false,
      errObjs: null,
      succeedCount: 0,
      failCount: 0,
    });
    //-----------------错误消息 end-------------

    const handleCreate = () => {
      data.createLoading = true;
      createBatchWayBill()
        .then(() => {
          data.createLoading = false;
        })
        .catch(() => {
          data.createLoading = false;
        });
    };

    onMounted(async () => {});

    return {
      gToKg,
      cmCubicToM,
      getLanguageName,
      getAddress,
      getErrorMsgKey,
      transportCharacteristicsEnum,
      destinationTypeEnum,

      columns,
      innerColumnsImport,
      data,

      funcValidationTableData,

      addressModal,
      hanldeShowAddressModal,
      handleAlterAddress,

      logistics,
      hanldeShowLogisticsModal,
      handleSelectLogistics,
      handleConfirmLogistics,

      rules,
      refForm,
      dropdownData,
      baseInfoModal,
      handleShowBaseInfoModal,
      handleBaseInfoModalOk,
      handleChangeWarehouseCodes,
      handelChangeDestinationType,

      boxModal,
      handleShowBoxModal,
      handleBoxModalOk,

      hanldeShowImporMessage,
      hanldeImportConfirm,
      funcWhetherCanImport,

      hanldeDownloadTemplateFile,
      hanldeDeleteData,
      hanldeDeleteBoxData,
      handleParseFile,
      handleCreate,
      errorMsgModal,
      //特别注释: 解析文件和验证数据接口入参和返回的重量都是kg, 其他接口的重量入参和出参都是g
    };
  },
});
</script>
