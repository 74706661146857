import {
  getCountrys,
  getProvinces,
  getCitys,
  getCountys,
} from "@/api/modules/common/index";

class AddressHelper {
  constructor(disableCache = false, cacheObj = null) {
    var _cacheObj = cacheObj;
    var _disableCache = disableCache;
    if (
      disableCache != null &&
      typeof disableCache != "boolean" &&
      cacheObj == null
    ) {
      _disableCache = false;
      _cacheObj = disableCache;
    }
    this.disableCache = _disableCache;
    this.initCacheStorage(_cacheObj);
  }

  initCacheStorage(cacheObj) {
    if (cacheObj) {
      this.cache = cacheObj;
    } else {
      this.cache = {};
    }
    if (!this.cache.provinces) {
      this.cache.provinces = {};
    }
    if (!this.cache.cities) {
      this.cache.cities = {};
    }
    if (!this.cache.counties) {
      this.cache.counties = {};
    }
  }

  async getCountries() {
    if (!this.disableCache && this.cache.countries) {
      return this.cache.countries;
    }
    let { result } = await getCountrys();
    if (!this.disableCache) {
      this.cache.countries = result;
    }
    return result;
  }

  async getProvinces(countryId) {
    if (countryId) {
      var cacheKey = countryId + "";
      if (!this.disableCache && this.cache.provinces[cacheKey]) {
        return this.cache.provinces[cacheKey];
      } else {
        let { result } = await getProvinces({ countryId: countryId });
        if (!this.disableCache) {
          this.cache.provinces[cacheKey] = result;
        }
        return result;
      }
    }
    return [];
  }

  async getCities(provinceId) {
    if (provinceId) {
      var cacheKey = provinceId + "";
      if (!this.disableCache && this.cache.cities[cacheKey]) {
        return this.cache.cities[cacheKey];
      } else {
        let { result } = await getCitys({ provinceId: provinceId });
        if (!this.disableCache) {
          this.cache.cities[cacheKey] = result;
        }
        return result;
      }
    }
    return [];
  }

  async getCounties(cityId) {
    if (cityId) {
      var cacheKey = cityId + "";
      if (!this.disableCache && this.cache.counties[cacheKey]) {
        return this.cache.counties[cacheKey];
      } else {
        let { result } = await getCountys({ cityId: cityId });

        if (!this.disableCache) {
          this.cache.counties[cacheKey] = result;
        }
        return result;
      }
    }
    return [];
  }
}

export function useNewAddrHelper(disableCache = false, cacheObj = null) {
  return new AddressHelper(disableCache, cacheObj);
}
